import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/seo'

class Quickbooks extends React.Component {
	render() {
		const siteTitle = 'Quickbooks'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<div>
				<SEO title={siteTitle} description={siteDescription} />
				<meta name="SKYPE_TOOLBAR" />
				<meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
				<title>QuickBooks</title>
				<meta name="description" />
				<meta name="keywords" />
				<link
					href="https://selfservice.s3.amazonaws.com/prod/22/landing/046-lp/css/046-lp-en.css"
					rel="stylesheet"
					type="text/css"
					media="screen"
				/>
				<style
					dangerouslySetInnerHTML={{
						__html:
							' <!-- a:link, a:visited, a:hover, a:active {  color:#2ca01c; } .cta {  color:#2ca01c; }  #button {    \tbackground: #2ca01c !important; } .custom_color { \tbackground-color: #2ca01c !important; } --> ',
					}}
				/>
				<style
					type="text/css"
					dangerouslySetInnerHTML={{
						__html:
							'<!-- html, body { margin: 0; padding: 0; width: 100%; min-width: 100%;}  a {      /*color: #FF7200;*/      text-decoration: none;  }    a img {    border: 0;  }   .style-orange {      color: #FF7200;  }    .editable{      word-wrap: break-word;      word-break: break-word;  }    .editable p+p {    margin-top: 10px;  }   .table_style-noborders,  table.table_style-noborders td,  table.table_style-noborders tr {      border: 0;  }    table.table_style-thinborder {      border-collapse: collapse;  }    .table_style-thinborder,  table.table_style-thinborder td {      border: 1px solid #000;  }    .preview_pdf {      width: 100%;      min-width: 100%;      background: #565656;      text-align: center;      padding: 4px 0px;      -moz-box-shadow: inset 0 0 3px 3px #222;      -webkit-box-shadow: inset 0 0 3px 3px #222;      box-shadow: inset 0 0 3px 3px #222;  }  .preview_pdf img {      margin: 10px auto;  }      .print_item .editable.image img {    width: 100% !important;    height: 100% !important;  }   -->',
					}}
				/>
				<div id="container">
					<div id="logo">
						<table
							width="100%"
							cellSpacing={0}
							cellPadding={0}
							border={0}
							style={{ margin: `0` }}
						>
							<tbody>
								<tr>
									<td style={{ margin: 0, padding: 0 }} width="33%">
										<img
											src="https://selfservice.s3.amazonaws.com/prod/22/landing/046-lp/images/qb-logo.jpg"
											alt=""
										/>
									</td>
									<td width="16%">
										<div
											className="editable logo @119x52"
											align="right"
											style={{ marginRight: `30px` }}
										>
											<img
												src="https://selfservice.s3.amazonaws.com/prod/22/logos/logo_user_92838_119x52-d7993975321c882e9651c073d9f4571f.png"
												alt="WSC"
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<table
							className="custom_color"
							width="100%"
							cellSpacing={0}
							cellPadding={0}
							border={0}
							bgcolor="#2ca01c"
						>
							<tbody>
								<tr>
									<td width="450px" valign="top" align="left">
										<div
											style={{
												fontFamily: 'Arial, Helvetica, sans-serif',
												padding: 0,
												fontSize: '38px',
												lineHeight: '43px',
												color: '#ffffff',
												margin: '20px',
												verticalAlign: 'top',
											}}
											className="editable title @70@4"
											slot_sn={2}
											default="Manage your business from anywhere"
										>
											Manage your business from anywhere
										</div>
									</td>
									<td width={500} valign="top" align="right">
										<div className="editable image" slot_sn={3}>
											<img
												default="https://selfservice.s3.amazonaws.com/prod/22/landing/046-lp/images/header-image.jpg"
												src="https://selfservice.s3.amazonaws.com/prod/22/image-options/046-lp-p1-en/image4-lp.jpg_iopreview.png"
												alt=""
												width={465}
												height={274}
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div
						id="content"
						className="boxout wide"
						style={{
							backgroundImage:
								'url(https://selfservice.s3.amazonaws.com/prod/22/landing/046-lp/images/compo.jpg)',
							padding: `0`,
						}}
					>
						<div id="content_title">
							<h2>
								QuickBooks Online simplifies financial management and
								<br /> allows you to access your accounts from anywhere, freeing
								<br /> up more time to focus on other business priorities.
							</h2>
							<h3>
								To find out more, please enter your details below and we’ll get
								in touch:
							</h3>
						</div>
						<div id="content_left">
							<form
								name="form1"
								action="https://selfservice.partnermarketing.com/form_submit/form1/863bfe8df280be0cf3d1157a1cc88eac"
								method="POST"
								id="registrationForm"
							>
								<div id="content_middle">
									<div id="errors">
										<h2>These fields are required</h2>
									</div>
									<div className="register">
										<div className="clear" />
									</div>
								</div>
								<div id="form_middle_left">
									<table
										className="table"
										width="80%"
										cellSpacing={0}
										cellPadding={0}
										border={0}
										style={{ marginBottom: `0rem` }}
									>
										<tbody>
											<tr>
												<td
													width="24%"
													style={{
														border: `none`,
														padding: `0`,
														fontSize: `14px`,
													}}
												>
													<nobr>First Name&nbsp;&nbsp;</nobr>
												</td>
												<td
													width="76%"
													style={{
														border: `none`,
														padding: `0`,
													}}
												>
													<label>
														<input
															type="text"
															name="firstname"
															id="firstname"
															className="textfield"
															required="required"
															data-message="This field is required"
														/>
													</label>
												</td>
											</tr>
											<tr>
												<td
													width="24%"
													style={{
														border: `none`,
														padding: `0`,
														fontSize: `14px`,
													}}
												>
													<nobr>Last Name&nbsp;</nobr>
												</td>
												<td
													width="76%"
													style={{ border: `none`, padding: `0` }}
												>
													<label>
														<input
															type="text"
															name="lastname"
															id="lastname"
															className="textfield"
															required="required"
															data-message="This field is required"
														/>
													</label>
												</td>
											</tr>
											<tr>
												<td
													width="24%"
													style={{
														border: `none`,
														padding: `0`,
														fontSize: `14px`,
													}}
												>
													<nobr>Company&nbsp;&nbsp;</nobr>
												</td>
												<td
													width="76%"
													style={{ border: `none`, padding: `0` }}
												>
													<label>
														<input
															type="text"
															name="company"
															id="company"
															className="textfield"
															required="required"
															data-message="This field is required"
														/>
													</label>
												</td>
											</tr>
											<tr>
												<td
													width="24%"
													style={{
														border: `none`,
														padding: `0`,
														fontSize: `14px`,
													}}
												>
													Position
												</td>
												<td
													width="76%"
													style={{ border: `none`, padding: `0` }}
												>
													<label>
														<input
															type="text"
															name="title"
															id="title"
															className="textfield"
															required="required"
															data-message="This field is required"
														/>
													</label>
												</td>
											</tr>
											<tr>
												<td
													width="24%"
													style={{
														border: `none`,
														padding: `0`,
														fontSize: `14px`,
													}}
												>
													<nobr>Email&nbsp;</nobr>
												</td>
												<td
													width="76%"
													style={{ border: `none`, padding: `0` }}
												>
													<label>
														<input
															type="text"
															name="email"
															id="email"
															className="textfield"
															required="required"
															data-message="This field is required"
														/>
													</label>
												</td>
											</tr>
											<tr>
												<td
													width="24%"
													style={{
														border: `none`,
														padding: `0`,
														fontSize: `14px`,
													}}
												>
													Telephone
												</td>
												<td
													width="76%"
													style={{ border: `none`, padding: `0` }}
												>
													<label>
														<input
															type="text"
															name="phone"
															id="phone"
															className="textfield"
															required="required"
															data-message="This field is required"
														/>
													</label>
												</td>
											</tr>
										</tbody>
									</table>
									<div id="form_bottom">
										<div id="form_bottom_right">
											<button
												type="submit"
												name="button"
												id="button"
												defaultValue="I want to know more"
												className="button"
											>
												I want to know more
											</button>
											{/*<a href='#' class='button'>I want to know more</a>*/}{' '}
										</div>
										<div className="clear" />
									</div>
								</div>
							</form>
						</div>
					</div>
					<div id="content" className="narrow">
						<div id="content_right" className="boxout2">
							<div id="content_right_content">
								<div
									className="cta editable title @40"
									style={{ margin: '10px 10px 10px 10px', color: '#505050' }}
									slot_sn={4}
									default="[Insert contact us heading]"
								>
									Contact WSC Group
								</div>
								<div
									className="cta editable title @20"
									style={{ margin: '10px 10px 10px 10px', color: '#2ca01c' }}
									slot_sn={5}
									default="[Insert phone]"
								>
									1300 365 125
								</div>
								<div style={{ margin: '10px 10px 10px 10px' }}>
									<a
										href="mailto:info@wscgroup.com.au"
										style={{ color: '#2ca01c', textDecoration: 'none' }}
										className="editable link email"
										slot_sn={6}
									>
										Email us
									</a>
								</div>
								<div style={{ margin: '10px 10px 10px 10px' }}>
									<a
										href="mailto:inf@wscgroup.com.au"
										style={{ color: '#2ca01c', textDecoration: 'none' }}
										className="editable link"
										slot_sn={7}
									>
										Visit our website
									</a>
								</div>
								<div
									className="cta editable title @40"
									style={{
										margin: '10px 10px 10px 10px',
										color: '#2ca01c',
										fontSize: '14px',
										lineHeight: '19px',
									}}
									slot_sn={8}
									default="[Insert headline here]"
								>
									Why chose cloud accounting?
								</div>
								<div
									style={{
										fontFamily: 'Arial, Helvetica, sans-serif',
										margin: '10px 10px 10px 10px',
										padding: 0,
										fontSize: '11px',
										lineHeight: '17px',
										color: '#505050',
										marginBottom: '20px',
									}}
									className="editable text @300"
									slot_sn={9}
									default="[Insert value proposition here]"
								>
									<p>
										Benefit from real-time reporting of your business
										performance.
									</p>
									<p>
										Give yourself greater flexibility in how to run your
										busienss.
									</p>
									<p>Access client accounts and invoice on the go.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="clear" />
				</div>
			</div>
		)
	}
}

export default Quickbooks

export const QuickbooksPageQuery = graphql`
	query QuickbooksPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
